import { defineChain } from 'viem';

// src/chainId.ts
var ChainId = /* @__PURE__ */ ((ChainId2) => {
  ChainId2[ChainId2["ETHEREUM"] = 1] = "ETHEREUM";
  ChainId2[ChainId2["GOERLI"] = 5] = "GOERLI";
  ChainId2[ChainId2["BSC"] = 56] = "BSC";
  ChainId2[ChainId2["BSC_TESTNET"] = 97] = "BSC_TESTNET";
  ChainId2[ChainId2["ZKSYNC_TESTNET"] = 280] = "ZKSYNC_TESTNET";
  ChainId2[ChainId2["ZKSYNC"] = 324] = "ZKSYNC";
  ChainId2[ChainId2["OPBNB_TESTNET"] = 5611] = "OPBNB_TESTNET";
  ChainId2[ChainId2["OPBNB"] = 204] = "OPBNB";
  ChainId2[ChainId2["POLYGON_ZKEVM"] = 1101] = "POLYGON_ZKEVM";
  ChainId2[ChainId2["POLYGON_ZKEVM_TESTNET"] = 1442] = "POLYGON_ZKEVM_TESTNET";
  ChainId2[ChainId2["ARBITRUM_ONE"] = 42161] = "ARBITRUM_ONE";
  ChainId2[ChainId2["ARBITRUM_GOERLI"] = 421613] = "ARBITRUM_GOERLI";
  ChainId2[ChainId2["SCROLL_SEPOLIA"] = 534351] = "SCROLL_SEPOLIA";
  ChainId2[ChainId2["LINEA"] = 59144] = "LINEA";
  ChainId2[ChainId2["LINEA_TESTNET"] = 59140] = "LINEA_TESTNET";
  ChainId2[ChainId2["BASE"] = 8453] = "BASE";
  ChainId2[ChainId2["BASE_TESTNET"] = 84531] = "BASE_TESTNET";
  ChainId2[ChainId2["MAP"] = 22776] = "MAP";
  return ChainId2;
})(ChainId || {});

// src/chainNames.ts
var chainNames = {
  [1 /* ETHEREUM */]: "eth",
  [5 /* GOERLI */]: "goerli",
  [56 /* BSC */]: "bsc",
  [97 /* BSC_TESTNET */]: "bscTestnet",
  [42161 /* ARBITRUM_ONE */]: "arb",
  [421613 /* ARBITRUM_GOERLI */]: "arbGoerli",
  [1101 /* POLYGON_ZKEVM */]: "polygonZkEVM",
  [1442 /* POLYGON_ZKEVM_TESTNET */]: "polygonZkEVMTestnet",
  [324 /* ZKSYNC */]: "zkSync",
  [280 /* ZKSYNC_TESTNET */]: "zkSyncTestnet",
  [59144 /* LINEA */]: "linea",
  [59140 /* LINEA_TESTNET */]: "lineaTestnet",
  [204 /* OPBNB */]: "opBNB",
  [5611 /* OPBNB_TESTNET */]: "opBnbTestnet",
  [8453 /* BASE */]: "base",
  [84531 /* BASE_TESTNET */]: "baseTestnet",
  [534351 /* SCROLL_SEPOLIA */]: "scrollSepolia",
  [22776 /* MAP */]: "map"
};
var chainNameToChainId = Object.entries(chainNames).reduce((acc, [chainId, chainName]) => {
  return {
    [chainName]: chainId,
    ...acc
  };
}, {});
var defiLlamaChainNames = {
  [56 /* BSC */]: "bsc",
  [1 /* ETHEREUM */]: "ethereum",
  [5 /* GOERLI */]: "",
  [97 /* BSC_TESTNET */]: "",
  [42161 /* ARBITRUM_ONE */]: "arbitrum",
  [421613 /* ARBITRUM_GOERLI */]: "",
  [1101 /* POLYGON_ZKEVM */]: "polygon_zkevm",
  [1442 /* POLYGON_ZKEVM_TESTNET */]: "",
  [324 /* ZKSYNC */]: "era",
  [280 /* ZKSYNC_TESTNET */]: "",
  [59140 /* LINEA_TESTNET */]: "",
  [84531 /* BASE_TESTNET */]: "",
  [204 /* OPBNB */]: "op_bnb",
  [5611 /* OPBNB_TESTNET */]: "",
  [534351 /* SCROLL_SEPOLIA */]: "",
  [59144 /* LINEA */]: "linea",
  [8453 /* BASE */]: "base",
  [22776 /* MAP */]: "map"
};

// src/utils.ts
function getChainName(chainId) {
  return chainNames[chainId];
}
function getLlamaChainName(chainId) {
  return defiLlamaChainNames[chainId];
}
function getChainIdByChainName(chainName) {
  if (!chainName)
    return void 0;
  return chainNameToChainId[chainName] ?? void 0;
}

// src/subgraphs.ts
var V3_SUBGRAPHS = {
  [1 /* ETHEREUM */]: "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-eth",
  [5 /* GOERLI */]: "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-goerli",
  [56 /* BSC */]: `https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-bsc`,
  [97 /* BSC_TESTNET */]: "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-chapel",
  [42161 /* ARBITRUM_ONE */]: "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-arb",
  [421613 /* ARBITRUM_GOERLI */]: "https://api.thegraph.com/subgraphs/name/chef-jojo/exhange-v3-arb-goerli",
  [1101 /* POLYGON_ZKEVM */]: "https://api.studio.thegraph.com/query/45376/exchange-v3-polygon-zkevm/version/latest",
  [1442 /* POLYGON_ZKEVM_TESTNET */]: null,
  [324 /* ZKSYNC */]: "https://api.studio.thegraph.com/query/45376/exchange-v3-zksync/version/latest",
  [280 /* ZKSYNC_TESTNET */]: "https://api.studio.thegraph.com/query/45376/exchange-v3-zksync-testnet/version/latest",
  [59144 /* LINEA */]: "https://graph-query.linea.build/subgraphs/name/pancakeswap/exchange-v3-linea",
  [59140 /* LINEA_TESTNET */]: "https://thegraph.goerli.zkevm.consensys.net/subgraphs/name/pancakeswap/exchange-v3-linea-goerli",
  [8453 /* BASE */]: "https://api.studio.thegraph.com/query/45376/exchange-v3-base/version/latest",
  [84531 /* BASE_TESTNET */]: "https://api.studio.thegraph.com/query/45376/exchange-v3-base-testnet/version/latest",
  [204 /* OPBNB */]: "https://opbnb-mainnet-graph.nodereal.io/subgraphs/name/pancakeswap/exchange-v3",
  [5611 /* OPBNB_TESTNET */]: null,
  [534351 /* SCROLL_SEPOLIA */]: "https://api.studio.thegraph.com/query/45376/exchange-v3-scroll-sepolia/version/latest",
  [22776 /* MAP */]: "https://graph.mapprotocol.io/subgraphs/name/hiveswap/exchange-v3-test"
  // [ChainId.MAP]: 'https://makalu-graph.maplabs.io/subgraphs/name/hiveswap/exchange-v3',
};
var V2_SUBGRAPHS = {
  [56 /* BSC */]: "https://proxy-worker-api.pancakeswap.com/bsc-exchange",
  [1 /* ETHEREUM */]: "https://api.thegraph.com/subgraphs/name/pancakeswap/exhange-eth",
  [1101 /* POLYGON_ZKEVM */]: "https://api.studio.thegraph.com/query/45376/exchange-v2-polygon-zkevm/version/latest",
  [280 /* ZKSYNC_TESTNET */]: "https://api.studio.thegraph.com/query/45376/exchange-v2-zksync-testnet/version/latest",
  [324 /* ZKSYNC */]: " https://api.studio.thegraph.com/query/45376/exchange-v2-zksync/version/latest",
  [59140 /* LINEA_TESTNET */]: "https://thegraph.goerli.zkevm.consensys.net/subgraphs/name/pancakeswap/exhange-eth/",
  [42161 /* ARBITRUM_ONE */]: "https://api.studio.thegraph.com/query/45376/exchange-v2-arbitrum/version/latest",
  [59144 /* LINEA */]: "https://graph-query.linea.build/subgraphs/name/pancakeswap/exhange-v2",
  [8453 /* BASE */]: "https://api.studio.thegraph.com/query/45376/exchange-v2-base/version/latest",
  [204 /* OPBNB */]: "https://opbnb-mainnet-graph.nodereal.io/subgraphs/name/pancakeswap/exchange-v2"
};
var map = defineChain({
  id: 22776,
  name: "Mapo",
  network: "mapo",
  nativeCurrency: {
    decimals: 18,
    name: "MAPO",
    symbol: "MAPO"
  },
  rpcUrls: {
    default: { http: ["https://rpc.maplabs.io"] },
    public: { http: ["https://rpc.maplabs.io"] }
  },
  blockExplorers: {
    etherscan: { name: "MapoScan", url: "https://maposcan.io" },
    default: { name: "MapoScan", url: "https://maposcan.io" }
  },
  contracts: {
    multicall3: {
      address: "0x4039fD92dF886065719122604F992b68BCe8c1C6",
      blockCreated: 9621720
    }
  },
  testnet: true
});
var merlin = defineChain({
  id: 4200,
  name: "Merlin",
  network: "merlin",
  nativeCurrency: {
    decimals: 18,
    name: "BTC",
    symbol: "BTC"
  },
  rpcUrls: {
    default: { http: ["https://rpc.merlinchain.io"] },
    public: { http: ["https://rpc.merlinchain.io"] }
  },
  blockExplorers: {
    etherscan: { name: "MerlinScan", url: "https://scan.merlinchain.io" },
    default: { name: "MerlinScan", url: "https://scan.merlinchain.io" }
  },
  contracts: {
    multicall3: {
      address: "0x56d3716e048D1B96C289C62Ec4Bd55E5B9C9d366",
      blockCreated: 229398
    }
  },
  testnet: false
});
var b2Haven = defineChain({
  id: 1102,
  name: "B\xB2 Haven Testnet",
  network: "haven",
  nativeCurrency: {
    decimals: 18,
    name: "tBTC",
    symbol: "tBTC"
  },
  rpcUrls: {
    default: { http: ["https://haven-rpc.bsquared.network"] },
    public: { http: ["https://haven-rpc.bsquared.network"] }
  },
  blockExplorers: {
    etherscan: { name: "B\xB2Scan", url: "https://haven-explorer.bsquared.network" },
    default: { name: "B\xB2Sca", url: "https://haven-explorer.bsquared.network" }
  },
  contracts: {
    multicall3: {
      address: "0x56d3716e048D1B96C289C62Ec4Bd55E5B9C9d366",
      blockCreated: 2285913
    }
  },
  testnet: true
});
var bevm = defineChain({
  id: 1501,
  name: "BEVM",
  network: "bevm",
  nativeCurrency: {
    decimals: 18,
    name: "BTC",
    symbol: "BTC"
  },
  rpcUrls: {
    default: { http: ["https://rpc-canary-1.bevm.io"] },
    public: { http: ["https://rpc-canary-1.bevm.io"] }
  },
  blockExplorers: {
    etherscan: { name: "BEVMScan", url: "https://scan-canary.bevm.io" },
    default: { name: "BEVMScan", url: "https://scan-canary.bevm.io" }
  },
  contracts: {
    multicall3: {
      address: "0x56d3716e048D1B96C289C62Ec4Bd55E5B9C9d366",
      blockCreated: 8982450
    }
  },
  testnet: false
});

export { ChainId, V2_SUBGRAPHS, V3_SUBGRAPHS, b2Haven, bevm, chainNameToChainId, chainNames, defiLlamaChainNames, getChainIdByChainName, getChainName, getLlamaChainName, map, merlin };
